import styled from "styled-components";
import React from 'react';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export const Img = ({src, visible, speed, position, objectFit, height, width, banner, alt, left, margin, right, top, bottom}) => {
    // const smoother = useContext(SmootherContext);
    //
    // useEffect(() => {
    //     smoother.effects("img", {speed: "auto"})
    // }, [])
    const DefaultBanner = '/images/static/blurbg.svg';

    return (
        <StyledImg className='global-image  ' objectFit={objectFit} margin={margin} position={position} left={left}
                   right={right} top={top}
                   bottom={bottom} height={height} width={width}>
            {
                src &&
                <img
                    alt={alt || 'Shanta Securities Limited'}
                    src={src || DefaultBanner} // use normal <img> attributes as props
                />

            }
        </StyledImg>

    );
};

const StyledImg = styled.div`
    position: ${props => props.position || 'absolute'};
    height: ${props => props.height || '100%'};
    width: ${props => props.width || '100%'};
    top: ${props => props.top || 0};
    left: ${props => props.left || 0};
    bottom: ${props => props.bottom || 0};
    right: ${props => props.right || 0};
    margin: ${props => props.margin || 0};
    overflow: hidden;

    img {
        width: 100%;
        height: 120svh;
        object-fit: ${props => props.objectFit || 'cover'};
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        will-change: transform;
        
        @media(max-width: 767px){
            height: 100% !important;
        }
    }
`;